<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="serviceShopList-container">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>店铺名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.name"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>所属平台/所属类目:</span>
        <el-cascader
          :options="platList"
          class="common-screen-input"
          :props="{ checkStrictly: true, label: 'platformName', value: 'id' }"
          clearable
          v-model="platId"
          @change="commonFun"
        >
        </el-cascader>
      </div>
      <div class="common-input-container">
        <span>所属客户:</span>
         <el-select
          class="common-screen-input"
          v-model="params.customerId"
          placeholder="请搜索客户"
          @change="companyFun"
          clearable
          filterable
          remote
          :remote-method="(val)=>{remoteMethod(val, 'custList')}"
        >
          <el-option
            v-for="item in finishedList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <el-button class="common-screen-btn" type="primary" @click="() => getShopList()"
        >查 询</el-button
      >
      <el-button
        class="common-screen-btn"
        plain
        @click="
          () => {
            resetBtn();
          }
        "
        >重 置</el-button
      >
    </div>
    <!-- <el-button class="common-screen-btn" type="primary" @click="addDrawer()"
      >新增店铺</el-button
    > -->
    <el-table class="common-table" :data="tableData" :fit="true">
      <el-table-column width="80px">
        <template slot-scope="scope">
          <span>
            <img
              class="common-iconImg"
              v-if="scope.row.shop_logo"
              :src="scope.row.shop_logo"
            />
            <span v-if="!scope.row.shop_logo"
              ><i class="el-icon-user common-iconImg-MR"></i
            ></span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="customer_name"
        label="所属客户"
        :formatter="tableColumn"
        width="160px"
      ></el-table-column>
      <el-table-column
        prop="shop_name"
        label="店铺名称"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <!-- <el-table-column
        prop="company_name"
        label="所属公司"
        :formatter="tableColumn"
        width="90px"
      ></el-table-column>
      <el-table-column
        prop="depart_name"
        label="所属部门"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column> -->
      <el-table-column
        prop="platform_name"
        label="所属平台"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="category_name"
        label="所属类目"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      
      <el-table-column
        prop="businessPeopleName"
        label="运营负责人"
        width="180px"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="create_name"
        label="创建人"
        :formatter="tableColumn"
        width="160px"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        :formatter="tableColumn"
        width="190"
        label="创建时间"
      ></el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => addDrawer(scope.row)"
            >查看/编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="
              () => {
                $router.push({
                  path: '/customer/company-service-shop-list/subaccount-list',
                  query: {
                    shopid: scope.row.id,
                    shopName: scope.row.shop_name,
                    companyName: scope.row.company_name,
                    departName: scope.row.depart_name,
                    categoryName: scope.row.category_name,
                    platformName: scope.row.platform_name,
                    customerName: scope.row.customer_name,
                    startTime: scope.row.start_time,
                    endTime: scope.row.end_time,
                    shopLogo: scope.row.shop_logo,
                    fileUrl: scope.row.file_url,
                  },
                });
              }
            "
            >子账号管理</el-button
          >
          <!-- &nbsp;&nbsp;&nbsp;
          <el-popconfirm
            placement="top"
            title="确定删除吗？"
            @confirm="
              () => {
                deleteCmsShop(scope.row.id);
              }
            "
          >
            <el-button type="text" size="small" slot="reference"
              >删除</el-button
            >
          </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { getCustList } from "../../service/common.js";
import { getUserList } from "../../service/manage.js";
import {
  getShopList,
  getPlatList,
  deleteCmsShop,
  shopLog
} from "../../service/customer.js";
import { Config, tableColumn } from "../../utils/index.js";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      increasing: 1,
      tableColumn,
      loading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "销售", isLink: false },
        { title: "店铺管理", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      userList: [], // 子组件用的下拉
      finishedList: [], // 所属客户下拉
      platList: [], // 所属平台/类目下拉
      platId: [], // 绑定的值
      tableRow: "", // 客户id用于编辑/查看
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      selectDepart: [],
      centerDialogVisible: false, // 修改密码员工弹窗
      drawer: false, // 新增员工弹窗
      roleSelect: "",
      tableData: [],
    };
  },
  created() {
    // this.getCustList();
    this.getShopList();
    this.getPlatList();

    this.getUserList();
  },
  methods: {
    remoteMethod (val, name) {
      if (name == 'custList') {
        this.getCustList(val)
      }
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getShopList();
    },
    async deleteCmsShop(id) {
      await deleteCmsShop({}, id);
      this.$message.success("删除成功");
      this.getShopList();
    },
    async getUserList() {
      // 商务负责下拉，暂用员工列表
      let resData = (await getUserList({ pageNum: -1 })).data;
      this.userList = resData;
    },
    async getCustList(name) {
      // 所属客户下拉
      let resData = (await getCustList({customerName: name})).data;
      this.finishedList = resData;
    },
    async getPlatList() {
      // 所属平台/类目
      let resData = (await getPlatList({ pageNum: -1 })).data;
      this.platList = resData;
    },
    resetBtn() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
      };
      this.platId = [];
      this.getShopList();
    },
    cascaderFun() {
      // 选择部门
      let params = { ...this.params };
      if (!params.companyId) {
        this.$message.error("请先选择所属公司");
        this.params.departId = [];
      }
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getShopList();
    },
    async getShopList() {
      this.loading = true;
      let platId = [...this.platId];
      let params = { ...this.params };
      if (params.departId && typeof params.departId == "object") {
        delete params.departId;
      }
      if (platId && platId.length) {
        params.platId = platId[0];
        if (platId[1]) {
          params.categoryId = platId[1];
        }
      }
      let resData = (await getShopList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.loading = false;
    },
    companyFun() {
      //选择公司筛选
      this.params.departId = [];
      this.getShopList();
    },
    handleClose() {
      this.drawer = false;
      this.getShopList();
    },
    async addDrawer(row) {
      //新增修改店铺
      if (row) {
        this.tableRow = row;
        let resData = (await shopLog({relationId: row.id, type: 1})).data
        this.$refs["cusListAdd"].getDataInfo("", row, '', resData);
      } else {
        this.$refs["cusListAdd"].getDataInfo("", "", "add");
      }
      this.drawer = true;
    },
    deleteItem() {},
  },
};
</script>
<style lang="less" scoped>
.serviceShopList-container {
  text-align: left;
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
.el-cascader-menu__empty-text::after {
  content: "请先选择公司";
}
</style>
