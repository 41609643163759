var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"serviceShopList-container",attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_c('breadcrumb',{attrs:{"BreadcrumbData":_vm.BreadcrumbData}}),_c('div',{staticClass:"common-screen-container"},[_c('div',{staticClass:"common-input-container"},[_c('span',[_vm._v("店铺名称:")]),_c('el-input',{staticClass:"common-screen-input",attrs:{"placeholder":"请输入"},model:{value:(_vm.params.name),callback:function ($$v) {_vm.$set(_vm.params, "name", $$v)},expression:"params.name"}})],1),_c('div',{staticClass:"common-input-container"},[_c('span',[_vm._v("所属平台/所属类目:")]),_c('el-cascader',{staticClass:"common-screen-input",attrs:{"options":_vm.platList,"props":{ checkStrictly: true, label: 'platformName', value: 'id' },"clearable":""},on:{"change":_vm.commonFun},model:{value:(_vm.platId),callback:function ($$v) {_vm.platId=$$v},expression:"platId"}})],1),_c('div',{staticClass:"common-input-container"},[_c('span',[_vm._v("所属客户:")]),_c('el-select',{staticClass:"common-screen-input",attrs:{"placeholder":"请搜索客户","clearable":"","filterable":"","remote":"","remote-method":function (val){_vm.remoteMethod(val, 'custList')}},on:{"change":_vm.companyFun},model:{value:(_vm.params.customerId),callback:function ($$v) {_vm.$set(_vm.params, "customerId", $$v)},expression:"params.customerId"}},_vm._l((_vm.finishedList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-button',{staticClass:"common-screen-btn",attrs:{"type":"primary"},on:{"click":function () { return _vm.getShopList(); }}},[_vm._v("查 询")]),_c('el-button',{staticClass:"common-screen-btn",attrs:{"plain":""},on:{"click":function () {
          _vm.resetBtn();
        }}},[_vm._v("重 置")])],1),_c('el-table',{staticClass:"common-table",attrs:{"data":_vm.tableData,"fit":true}},[_c('el-table-column',{attrs:{"width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[(scope.row.shop_logo)?_c('img',{staticClass:"common-iconImg",attrs:{"src":scope.row.shop_logo}}):_vm._e(),(!scope.row.shop_logo)?_c('span',[_c('i',{staticClass:"el-icon-user common-iconImg-MR"})]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"customer_name","label":"所属客户","formatter":_vm.tableColumn,"width":"160px"}}),_c('el-table-column',{attrs:{"prop":"shop_name","label":"店铺名称","formatter":_vm.tableColumn,"width":"180px"}}),_c('el-table-column',{attrs:{"prop":"platform_name","label":"所属平台","formatter":_vm.tableColumn,"width":"180px"}}),_c('el-table-column',{attrs:{"prop":"category_name","label":"所属类目","formatter":_vm.tableColumn,"width":"180px"}}),_c('el-table-column',{attrs:{"prop":"businessPeopleName","label":"运营负责人","width":"180px","formatter":_vm.tableColumn}}),_c('el-table-column',{attrs:{"prop":"create_name","label":"创建人","formatter":_vm.tableColumn,"width":"160px"}}),_c('el-table-column',{attrs:{"prop":"create_time","formatter":_vm.tableColumn,"width":"190","label":"创建时间"}}),_c('el-table-column',{attrs:{"label":"操作","width":"200","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function () { return _vm.addDrawer(scope.row); }}},[_vm._v("查看/编辑")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function () {
              _vm.$router.push({
                path: '/customer/company-service-shop-list/subaccount-list',
                query: {
                  shopid: scope.row.id,
                  shopName: scope.row.shop_name,
                  companyName: scope.row.company_name,
                  departName: scope.row.depart_name,
                  categoryName: scope.row.category_name,
                  platformName: scope.row.platform_name,
                  customerName: scope.row.customer_name,
                  startTime: scope.row.start_time,
                  endTime: scope.row.end_time,
                  shopLogo: scope.row.shop_logo,
                  fileUrl: scope.row.file_url,
                },
              });
            }}},[_vm._v("子账号管理")])]}}])})],1),_c('el-pagination',{staticStyle:{"text-align":"center"},attrs:{"page-sizes":_vm.pagination.pageSizes,"page-size":_vm.params.pageSize,"current-page":_vm.params.pageNum,"layout":"total, prev, pager, next,sizes, jumper","total":_vm.pagination.total},on:{"size-change":function (e) { return _vm.pageFun(e, 'pageSize'); },"current-change":function (e) { return _vm.pageFun(e); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }